import React, { Component } from 'react';

import { withFirebase } from '../Firebase';
import LightboxList from './LightboxList';
import CreateLightbox from './CreateLightbox';

class Lightboxes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: '',
      loading: false,
      lightboxes: [],
      authRef: this.props.firebase.user(this.props.authUser.uid),
      lastVisible: null,
      firstVisible: null,
      pageSize: 9,
      pageHistory: [],
      searchText: '',
    };
  }

  componentDidMount() {
    this.onListenForLightboxes();
  }

  onListenForLightboxes = ({ action } = {}) => {
    this.setState({ loading: true });

    let { lastVisible, pageHistory } = this.state
    if (action === 'SEARCH') {
      lastVisible = null
      pageHistory = []
    }

    let query = this.props.firebase
      .lightboxes()

    // handling for user role
    if (this.props.authUser.admin) {
      if (this.state.searchText) {
        const searchText = this.state.searchText
        query = query
        .where('name', '>=', searchText)
        .where('name', '<', searchText + '\uf8ff')
        .orderBy('name')
      }

      query = query.orderBy('modifiedAt', 'desc')
      .limit(this.state.pageSize);
    } else {
      query = query.where("creatorID", "==", this.props.authUser.uid)
      .orderBy('modifiedAt', 'desc')
      .limit(this.state.pageSize);
    }

    // handling for pagination
    if (lastVisible) {
      query = query.startAfter(lastVisible);
    }

    this.unsubscribe = query.onSnapshot(async snapshot => {
      if (snapshot.size) {
        let lightboxes = [];
        snapshot.forEach(doc => 
          lightboxes.push({ ...doc.data(), uid: doc.id })
        );

        this.setState(() => ({
          lightboxes: lightboxes,
          lastVisible: snapshot.docs[snapshot.docs.length - 1],
          firstVisible: snapshot.docs[0],
          pageHistory: [...pageHistory, lightboxes],
          loading: false,
        }));
      } else {
        this.setState({ lightboxes: null, loading: false });
      }
    });
  };

  loadNextPage = () => {
    this.onListenForLightboxes();
  };

  loadPrevPage = () => {
    this.setState(prevState => {
      const { pageHistory } = prevState;
      if (pageHistory.length > 1) {
        const newLightboxes = pageHistory[pageHistory.length - 2];
        return {
          lightboxes: newLightboxes,
          pageHistory: pageHistory.slice(0, -1),
          loading: false,
        };
      }
      return { loading: false };
    });
  };

  onChangeText = event => {
    this.setState({ text: event.target.value });
  };

  onEditLightbox = (lightbox, text) => {
    const { uid, ...lightboxSnapshot } = lightbox;

    this.props.firebase.lightbox(lightbox.uid).update({
      ...lightboxSnapshot,
      text,
      editedAt: this.props.firebase.fieldValue.serverTimestamp(),
    });
  };

  onCreateLightbox = name => {
    this.props.firebase.lightboxes().add({
      createdAt: this.props.firebase.NOW(),
      modifiedAt: this.props.firebase.NOW(),
      creator: this.state.authRef,
      creatorID: this.props.authUser.uid,
      images: [],
      name
    })
    .then(function (docRef) {
      console.log("Lightbox created with ID: ", docRef.id);
    })
    .catch(function (error) {
      console.error("Error creating lightbox: ", error);
    });
  }

  onSearchInputChange = (event) => {
    this.setState({ searchText: event.target.value })
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const { authUser } = this.props;
    const { text, lightboxes, loading, pageHistory } = this.state;

    return (
      <div>

        <div className="header-row">
          <div className="half-col caps-text-13 red">
            <span>My Lightboxes</span>
            <div className="lightbox-search-wrapper search-archive-parent-secondary">
              <input className="search-archive-input full" placeholder="enter lightbox name" onChange={this.onSearchInputChange} />
              <button className="search-archive-secondary-button" onClick={() => this.onListenForLightboxes({ action: 'SEARCH' })}>Search</button>
            </div>
          </div>
          <div className="half-col right">
            {!loading && <CreateLightbox onCreateLightbox={this.onCreateLightbox}/>}
          </div>
        </div>

        {loading && <div>Loading...</div>}

        {lightboxes && (
          <LightboxList
            authUser={authUser}
            lightboxes={lightboxes}
            onEditLightbox={this.onEditLightbox}
            onRemoveLightbox={this.onRemoveLightbox}
          />
        )}

        {!lightboxes && <div>You haven't created any lightboxes yet, get started now!</div>}

        {lightboxes && lightboxes.length > 0 && (
          <div className='lightboxes-paginations'>
            <button className='lightboxes-paginations-button' onClick={this.loadPrevPage} disabled={pageHistory.length <= 1}>Previous</button>
            <button className='lightboxes-paginations-button' onClick={this.loadNextPage} disabled={lightboxes.length < this.state.pageSize}>Next</button>
          </div>
        )}


        {/* <form
          onSubmit={event =>
            this.onCreateLightbox(event, authUser)
          }
        >
          <input
            type="text"
            value={text}
            onChange={this.onChangeText}
          />
          <button type="submit">Send</button>
        </form> */}
      </div>
    );
  }
}

export default withFirebase(Lightboxes);
