import React, { Component } from 'react';
import fileDownload from 'js-file-download';
import { withFirebase } from '../Firebase';
import DisplayFiles from '../DisplayFiles';
import { AuthUserContext } from '../Session';
import { UPLOAD } from '../../constants/routes';
import history from '../../history';

const typeConversions = {
  'vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
  'vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
  'mpeg': 'mp3',
}

class Upload extends Component {
  constructor(props) {
    super(props);

    var params = {};
    window.location.search.substr(1).replace(/([^=&]+)=([^&]*)/g, function (m, key, value) {
      params[decodeURIComponent(key)] = decodeURIComponent(value);
    });

    this.state = {
      ...params,
      name: '',
      files: [],
      displayFiles: [],
      created: '',
    }
  }

  componentDidMount() {
    this.props.firebase.uploads(this.state.d)
      .listAll().then((res) => {
        let displayFiles = []

        let downloadPromises = []
        let metadataPromises = []
        let names = []
        res.items.forEach(function(itemRef) {
          downloadPromises.push(itemRef.getDownloadURL())
          metadataPromises.push(itemRef.getMetadata())
          names.push(itemRef.name)
        })

        Promise.all(downloadPromises).then(urls => {
          Promise.all(metadataPromises).then(metadata => {
            if (metadata.length > 0 && metadata[0].timeCreated) {
              const createdDate = new Date(metadata[0].timeCreated);
              if (createdDate) {
                this.setState({created: createdDate.toLocaleDateString()})
              }
            }
            for (let i in urls) {
              displayFiles.push({
                url: urls[i],
                type: metadata[i].customMetadata.renderType
                    || metadata[i].contentDisposition.split(".")[metadata[i].contentDisposition.split(".").length - 1]
                    || metadata[i].type
                    || null,
                name: names[i]
              })
            }

            let nameURL = displayFiles.shift().url
            fetch(nameURL)
              .then(res => res.blob())
              .then(blob => {
                var reader = new FileReader();
                  reader.readAsText(blob, "UTF-8");
                  reader.onload = (evt) => {
                    this.setState({
                      name: evt.target.result,
                      displayFiles
                    })
                  }
                  reader.onerror = (evt) => {
                    console.log("error reading name file")
                }
            });
          })
        })
      }).catch(err => {
        console.log(err)
      });
  }

  downloadFile(i) {
    if (-1 < i && i < this.state.displayFiles.length) {
      window.open(this.state.displayFiles[i].url, '_blank')
    }
  }

  onDelete() {
    this.props.firebase.uploads(this.state.d).listAll().then(files => {
      let deletePromises = []
      files.items.forEach(function(file) {
        deletePromises.push(file.delete())
      })
      Promise.all(deletePromises).then(() => {
        history.push(UPLOAD);
      })
    })
  }

  render() {
    const { displayFiles, files, name, created } = this.state;

    return(
      <AuthUserContext.Consumer>
        {authUser =>
          <div>
            <div className="header-row">
              <div className="half-col">
                <div className="caps-text-13 red">Download</div>
              </div>
              <div className="half-col right">
              </div>
            </div>
            <div>
              <div className="modal-input file-tab">{name} ({created})</div>
              {authUser && authUser.admin && <button className="header-button" onClick={() => this.onDelete()}>Delete</button>}
            </div>
            <section className="upload-dropzone-parent">
              <DisplayFiles displayFiles={displayFiles} downloadFile={(i) => this.downloadFile(i)} />
            </section>
          </div>
        }
      </AuthUserContext.Consumer>
    )
  }
}

export default withFirebase(Upload);
