import React, { Component } from 'react';
import arrayMove from 'array-move';

import { withFirebase } from '../Firebase';
import SortableGrid from './SortableGrid'
import DeleteLightbox from '../DeleteLightbox';
import GalleryItem from '../GalleryItem';
import ShareLightbox from '../ShareLightbox';
import DuplicateLightbox from '../DuplicateLightbox';
import PrintLightbox from '../PrintLightbox';

class Lightbox extends Component {
  constructor(props) {
    super(props);

    var params = {};
    window.location.search.substr(1).replace(/([^=&]+)=([^&]*)/g, function (m, key, value) {
      params[decodeURIComponent(key)] = decodeURIComponent(value);
    });

    this.state = {
      ...params,
      loading: false,
      rearranging: false,
      name: '',
      lightbox: {
        images: []
      },
      mode: 0
    }
    // modes: 0 - unauthenticated, 1 - logged in, non-owner, 2 - owner
  }

  componentDidMount() {
    this.onListenForImages();
  }

  getMode(lightbox) {
    let mode = 0
    if (lightbox && this.props.authUser) {
      if (this.props.authUser.admin || this.props.authUser.uid === lightbox.creator.id) {
        mode = 2
      } else {
        mode = 1
      }
    }
    return mode
  }

  componentDidUpdate(prevProps) {
    if (prevProps.authUser !== this.props.authUser) {
      this.setState({
        mode: this.getMode(this.props.lightbox)
      });
    }
  }

  onListenForImages = () => {
    this.setState({
      loading: true
    });

    this.unsubscribe = this.props.firebase
      .lightbox(this.state.l)
      .onSnapshot(async snapshot => {
        let lightbox = snapshot.data()
        if (lightbox) {
          for (let i = 0; i < lightbox.images.length; i++) {
            let image = await lightbox.images[i].get()
            let id = lightbox.images[i].id
            const imageData = image.data()
            if (imageData) {
              lightbox.images[i] = imageData
              lightbox.images[i].uid = id
              lightbox.images[i].id = id
            }
          }
          lightbox.images = lightbox.images.filter(image => image.uid)
          lightbox.uid = this.state.l

          let mode = this.getMode(lightbox)

          this.setState({
            lightbox: lightbox,
            loading: false,
            name: lightbox.name,
            mode
          });
        } else {
          this.setState({
            loading: false
          });
        }
      });
  };

  componentWillUnmount() {
    this.unsubscribe();
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    let images = arrayMove(this.state.lightbox.images, oldIndex, newIndex)
    this.setState({
      lightbox: {
        ...this.state.lightbox,
        images
      }
    });

    let imageRefs = []
    for (let i = 0; i < images.length; i++) {
      imageRefs[i] = this.props.firebase.image(images[i].uid)
    }

    this.props.firebase
      .lightbox(this.state.lightbox.uid)
      .update({
        images: imageRefs,
        modifiedAt: this.props.firebase.NOW()
      })
  };

  toggleRearranging = () => {
    this.setState({
      rearranging: !this.state.rearranging
    });
  };

  setHidden(image, newState) {
    let newHidden = []
    if (this.state.lightbox.hidden) {
      newHidden = this.state.lightbox.hidden
    }
    if (newState) {
      newHidden.push(image.archiveNumber)
    } else {
      newHidden = newHidden.filter(id => id !== image.archiveNumber)
    }

    this.props.firebase
      .lightbox(this.state.lightbox.uid)
      .update({
        hidden: newHidden,
        modifiedAt: this.props.firebase.NOW()
      })
  }

  onChangeName = event => {
    let val = event.target.value.replace(/[^0-9a-zA-Z ,:/'"<>_\-!@#$%^&*()+=?;]/g, '');
    this.setState({ name: val });
  };

  renameLightbox() {
    const { lightbox, name } = this.state
    if (lightbox.name !== name) {
      this.props.firebase
        .lightbox(this.state.lightbox.uid)
        .update({
          name,
          modifiedAt: this.props.firebase.NOW()
        })
    }
  };

  render() {
    const { loading, rearranging, lightbox, mode, name } = this.state;

    return(
      <div>
        <div className="header-row">
          <div className="half-col bottom-for-mobile">{!loading? <input className="lightbox-title-input" onChange={(e) => this.onChangeName(e)} onBlur={() => this.renameLightbox()} value={name} placeholder="Untitled Lightbox"/> : null}</div>
          {!loading?
            <div className="half-col right">
              {mode === 2?
                <button className="mobile-hide header-button top" onClick={() => this.toggleRearranging()}>
                  <img className="header-button-img" style={{height: '26px', marginBottom: "-3px"}} src={rearranging? "/ARCH_icon_rearrange_red.svg" : "/ARCH_icon_rearrange.svg" }/>
                  <div className={rearranging ? "header-button-text red" : "header-button-text"}>rearrange</div>
                </button>
              :null}
              <span className="mobile-hide">
                <PrintLightbox lightbox={lightbox}/>
              </span>
              {mode === 1 || mode === 2?
                <span className="mobile-hide">
                  <DuplicateLightbox lightbox={lightbox}/>
                </span>
              :null}
              {mode === 2?
                <DeleteLightbox lightbox={lightbox}/>
              :null}
              <ShareLightbox lightbox={lightbox}/>
            </div>
          :null}
        </div>

        {loading && <div>Loading...</div>}

        {!loading && lightbox && rearranging? 
          <SortableGrid
            lightbox={lightbox}
            hidden={lightbox.hidden}
            onSortEnd={this.onSortEnd}
            axis="xy"
            useWindowAsScrollContainer/>
        : null}

        <div className="gallery-full">
          {!loading && lightbox && !rearranging ?
            lightbox.images.map((image, index) => (
              lightbox.hidden && lightbox.hidden.includes(image.archiveNumber) && mode !== 2?
                null
              :
                <GalleryItem removeOnly={true} setHidden={(h) => this.setHidden(image, h)} hidden={lightbox.hidden && lightbox.hidden.includes(image.archiveNumber)} overlayEnabled={mode === 2} uneditable={mode !== 2} key={index} authUser={this.props.authUser} image={image} lightboxes={{[lightbox.uid]: lightbox}}/>
            ))
          : null}
        </div>

        {!loading && lightbox && lightbox.images.length === 0 && <div>This lightbox has no images! Visit the archive to begin adding some.</div>}

        {!loading && !lightbox && <div>No lightbox found</div>}
      </div>
    )
  }
}

export default withFirebase(Lightbox);
